import * as React from "react"
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StaticImage } from "gatsby-plugin-image"

export default function FourthPage() {
  return (
    <Layout>
      <Seo title="About Us" />
      <Navbar />

      <section id="hero" style={{ paddingTop: "60px" }}>
        <div className="container">
          <div className="row pt-5 pb-5 justify-content-between align-items-center">
            <div className="col-lg-6 pt-lg-0 order-1 order-lg-1">
              <StaticImage
                src="../images/about-us.svg"
                alt=""
                placeholder="none"
                quality="100"
              />
            </div>
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h1 className="fs-2" style={{color: "#FFFFFF"}}>About Us</h1>
                <h2
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.3", color: "#ffffff" }}
                >
                  DataAlp is neural network (deep learning) based solution for
                  autonomous data driven optimization. It comes from realization
                  of how increased computational abilities have allowed
                  techniques like neural networks to precisely learn, analyze,
                  model and make predictions using data. These powerful data
                  tools have created a paradigm shift, from simply knowledge
                  being power to omnipotence of data. However, even with data
                  being readily available in almost every big/small organization
                  or entity, not everyone has the resources or skills to extract
                  valuable insights. DataAlp aims to catalyze this transition
                  towards the data driven optimization by making data analysis a
                  greener, simpler, autonomous and a free process for all.
                  DataAlp is a child company of AGOS LLC.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 pt-lg-0 order-sm-12 order-md-1 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h3 className="mb-5">What We Offer</h3>
                <h2
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.3" }}
                >
                  This website is currently compatible for supervised and
                  unsupervised learning datasets. If you are not sure about your
                  dataset, the Alp still has you covered. Just upload your data
                  and Alp will according process your dataset and tell you its
                  story. However, DataAlp relies on small tips to maintain and
                  continue improving the customer experience by providing better
                  insights and stories for all kinds of dataset. There is always
                  more coming in terms of what this startup can provide as this
                  is only a start. So please be generous with your tips & do
                  leave us with the feedback on what needs to be improved.
                  Moreover, if there is a specific task you need Alp to help you
                  with, you can always fill the form under 'Hire An Alp' section
                  and an Alp will be there to get it done for you.
                </h2>
              </div>
            </div>
            <div className="col-lg-6 pt-lg-0 order-sm-1 order-md-2 order-lg-2 d-flex justify-content-center">
              <StaticImage
                src="../images/Financial-data-cuate.svg"
                alt=""
                placeholder="none"
                quality="100"
              />
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 pt-lg-0 order-1 order-lg-1 d-flex justify-content-center">
              <StaticImage
                src="../images/Happy-Earth-amico.svg"
                alt=""
                placeholder="none"
                quality="100"
              />
            </div>
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-2 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h3 className="mb-5">What Makes Us Special
</h3>
                <h2
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.3" }}
                >
                  DataAlp is an environmental friendly approach to data driven
                  optimization. The website is not linked to any database at the
                  backend, as they are the highest contributor to emissions
                  within the IT industry and will contribute upto 14% of total
                  emissions by 2040. Without a database, all the uploaded
                  datasets are read as pandas dataframe and then passed down to
                  the algorithms to turn your dataset in to a story. All this
                  while all the pdfs objects are created in memory stream and
                  merged together as a memory file, which is then emailed to you
                  and the memory stream/files are closed to keep no record of
                  the created files. This offers both the necessary data
                  confidentiality to the user and eliminates the need for a
                  database.
                </h2>
                <h2
                  className="fs-5 pt-3"
                  style={{ textAlign: "justify", lineHeight: "1.3" }}
                >
                  Furthermore, DataAlp makes use of automation to provide
                  reliable data science services for free, reducing the reliance
                  and need for everyone to acquire data science skills for
                  extracting valuable insights from their datasets. This can
                  help in reducing a portion of energy consumption/money that
                  goes into acquiring data science skills around the world,
                  especially in the third world countries where every
                  socio/economic sector can benefit from easily and cheaply
                  available data driven optimization resources.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-6 pt-lg-0 order-2 order-lg-2 d-flex justify-content-center">
              <StaticImage
                src="../images/neural-network.svg"
                alt=""
                placeholder="none"
                quality="100"
                style={{width: '70%'}}
              />
            </div>
            <div className="col-lg-6 pt-lg-0 order-1 order-lg-1 d-flex align-items-center">
              <div data-aos="zoom-out">
                <h3 className="mb-5">Why Neural Networks</h3>
                <h2
                  className="fs-5"
                  style={{ textAlign: "justify", lineHeight: "1.3" }}
                >
                  A neural network utilizes a series of algorithms to recognize
                  any underlying patterns/relationships in a dataset, using a
                  process that mimics the way human brain operates. Today neural
                  networks/deep learning is the best performing form of
                  artificial intelligence. The learning process is so robust
                  that a same neural network can replicate its performance over
                  various datasets. Inevitably making them a perfect application
                  for autonomous systems. However, DataAlp utilizes symbolic AI
                  for recognizing various dataset types & the following data
                  preprocessing while, only uses neural networks for training
                  the model. Moreover, the Alp also utilizes
                  algorithms/techniques like randomforest, Kmeans, LDA (Linear
                  Discriminant Analysis) or other dimensionality reduction
                  techniques like PCA (Principal Component Analysis) or t-SNE
                  (T-Distributed Stochastic Neighbor Embedding) to offer the
                  best data story & insights to our users.
                </h2>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}
